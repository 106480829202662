import React, {Component, Fragment} from "react"
import PropTypes from "prop-types"
import {connect} from "react-redux"
import {injectIntl} from "react-intl"
import isEqual from "lodash/isEqual"
import {Link} from "react-router-dom"
import PageVisibility from "react-page-visibility"
import {Container, Row, Col} from "react-grid-system"
import classnames from "classnames"
import DocumentTitle from "react-document-title"
import {Route, Switch} from "react-router-dom"
import {ThemeProvider} from "styled-components"
import {CookiesProvider} from "react-cookie"
import ReactTooltip from "react-tooltip"
import {StickyContainer, Sticky} from "react-sticky" // eslint-disable-line
import {withController} from "react-scroll-parallax"

import * as actions from "../data/actions"
import * as selectors from "../data/selectors"
import HelperPlatform from "../data/utils/HelperPlatform"

import GlobalStyle from "./GlobalStyle"

import SNotFound from "../view/screens/SNotFound"
import {getScreenComponentForPageTemplate} from "./Screens"

import CHeaderMenu from "../view/components/CHeaderMenu"
import CAlert from "../view/components/CAlert"
import CVideoOverlay from "../view/components/CVideoOverlay"
import CImageOverlay from "../view/components/CImageOverlay"
import CLightbox from "../view/components/CLightbox"
import CCookieNotice from "../view/components/CCookieNotice"
import CBestOfSwissApps from "../view/components/CBestOfSwissApps"
import CReferrerCodePopup from "../view/components/CReferrerCodePopup"

import {basicTheme} from "../view/Theme"

import {debbify} from "../data/selectors/helpers"
const debby = (...args) => debbify("AppWithState", ...args)

const DEBUG = false && __DEV__
const DEBUG_MEDIA_QUERY = true && __DEV__

let intlLocaleByBrowserLangSetOnce = false // for /linking, /g/*, /g/*/p/*

class AppWithState extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    stageDimensions: PropTypes.object,
    mediaQueryClass: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
    location: PropTypes.object.isRequired,
    waypointClassName: PropTypes.string,
    langs: PropTypes.array,
    intlLocale: PropTypes.string,
    intlLocaleLocation: PropTypes.string, // the one of the actual location
    sitemapData: PropTypes.array,
    documentTitle: PropTypes.string,
    currentPageLinkOtherLangs: PropTypes.object,
    locationPathname: PropTypes.string.isRequired,
    pageTemplate: PropTypes.string,
    deviceLocaleUsable: PropTypes.string,
    headerMenuFilledWhenScrolledUp: PropTypes.bool,
    embeddedMode: PropTypes.bool,
    parallaxController: PropTypes.object,
    tabVisible: PropTypes.bool,
  }

  constructor(props) {
    super(props)
    this.state = {
      drawPlaceholdersBottom: true,
    }
  }

  componentDidMount = () => {
    debby("componentDidMount()")
    const {dispatch} = this.props

    window.addEventListener("resize", this.onWindowResize) // set store's stage dimensions
    this.onWindowResize()

    this.handleProps(this.props, true)
    this.timeoutIdDrawPlaceholdersBottom = setTimeout(() => this.setState({drawPlaceholdersBottom: false}), 500)

    dispatch(actions.requestApiData()).then(() => {})
    dispatch(actions.requestSuggestedCurrency()).then(({success, suggestedCurrency}) => {}) // eslint-disable-line

    const urlParams = selectors.getAllUrlParams(window.location.href)

    // const debugMode = selectors.getObjectDeep(urlParams, "debug", "false") == "true"
    // dispatch(actions.setDebugModeActive(debugMode))

    //  check for referrer code - https://iazzu.com/wp/wp-admin/edit.php?post_type=referrercode
    const referrerCodeParams = selectors.getObjectDeep(urlParams, "refc")
    if (!!referrerCodeParams) {
      dispatch(actions.setReferrerCode(referrerCodeParams)) // ASession.js
    }

    const embeddedMode = selectors.getObjectDeep(urlParams, "embed", "false") == "true"
    dispatch(actions.setEmbeddedModeActive(embeddedMode)) // ASession.js

    this.updateParallaxController()
    this.updateParallaxControllerTimeoutId = setTimeout(this.updateParallaxController, 500)
    // this.updateParallaxControllerIntervalId = setInterval(this.updateParallaxController, 5000)
  }

  updateParallaxController = () => {
    if (!this.props.tabVisible) {
      return
    }
    // debby("updateParallaxController()")
    this.props.parallaxController && this.props.parallaxController.update()
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  UNSAFE_componentWillReceiveProps = nextProps => this.handleProps(nextProps, false)

  handleProps = (nextProps, initial) => {
    if (initial || nextProps.intlLocale != nextProps.intlLocaleLocation) {
      // console.warn(`AppWithState.handleProps(): setIntlLocale here should be avoided by injecting postData properly in PHP.`, {intlLocale: nextProps.intlLocale, intlLocaleLocation: nextProps.intlLocaleLocation})
      if (!!nextProps.pageTemplate) {
        const useBrowserLanguage = selectors.getUseBrowserLanguageForPageTemplate(nextProps.pageTemplate)
        if (!useBrowserLanguage) {
          this.props.dispatch(actions.setIntlLocale(nextProps.intlLocaleLocation))
        } else {
          // Linking, Gallery Landing, Gallery Landing Artwork
          if (window.browserLang != nextProps.intlLocale && !intlLocaleByBrowserLangSetOnce) {
            intlLocaleByBrowserLangSetOnce = true
            debby(`handleProps(): Changing locale to browser language (${window.browserLang})..`)
            this.props.dispatch(actions.setIntlLocale(window.browserLang))
          }
        }
      }
    }

    if (this.props.location.pathname != nextProps.location.pathname) {
      window.scrollTo(0, 0)
    }
    if (initial || this.props.location.pathname != nextProps.location.pathname) {
      this.props.dispatch(actions.requestPostData(nextProps.location.pathname))
      this.props.dispatch(actions.sendAnalyticsPageView(nextProps.location.pathname))
      HelperPlatform.setDocumentLang(nextProps.intlLocale) // for word-wrap
    }
  }

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.onWindowResize)
    clearTimeout(this.timeoutIdDrawPlaceholdersBottom)
    clearTimeout(this.updateParallaxControllerTimeoutId)
    clearInterval(this.updateParallaxControllerIntervalId)
  }

  onWindowResize = () => this.props.dispatch(actions.setStageDimensions(window.innerWidth, window.innerHeight))

  handleVisibilityChange = isVisible => {
    debby(`handleVisibilityChange (..)`, {isVisible})
    this.props.dispatch(actions.sendAnalyticsEvent(`tab_visibility_set_to_${JSON.stringify(isVisible)}`))
    this.props.dispatch(actions.setTabVisible(isVisible)) // ADevice
  }

  renderRouterSwitch = () => {
    const {sitemapData, location} = this.props
    if (!sitemapData) {
      return <div style={{height: "1000vh"}} />
    }
    debby("renderRouterSwitch()", {sitemapData})
    return (
      <Switch location={location}>
        {/* Since this doesn't include the Gallery Landing page, .. */}
        {sitemapData
          .filter(entry => entry.hasOwnProperty("pageTemplate")) // don't explicitly render single Knowledge Base Articles (returned in NBSitemap.php)
          .map(entry => {
            if (!!entry && !!entry.pageTemplate && !!entry.langs) {
              const {pageTemplate, langs} = entry
              const component = getScreenComponentForPageTemplate(pageTemplate) //  Wordpress template filename
              return Object.keys(langs).map(lang => (
                <Route
                  //
                  key={`route_${lang}_${pageTemplate}`}
                  exact
                  path={langs[lang].path}
                  component={component}
                />
              ))
            }
          })}
        <Route component={SNotFound} />
      </Switch>
    )
  }

  render = () => {
    const {drawPlaceholdersBottom} = this.state
    const {intl, pageTemplate, stageDimensions, mediaQueryClass, waypointClassName, intlLocale, documentTitle, currentPageLinkOtherLangs, locationPathname, headerMenuFilledWhenScrolledUp, sitemapData, embeddedMode} = this.props
    const useStickyContainer = false
    debby("render()", {pageTemplate, sitemapData})
    return (
      <PageVisibility onChange={this.handleVisibilityChange}>
        <CookiesProvider>
          <ThemeProvider theme={basicTheme}>
            {!!documentTitle && <DocumentTitle title={documentTitle} />}
            <GlobalStyle />

            {!embeddedMode && (
              <CHeaderMenu
                //
                locationPathname={locationPathname}
                currentPageLinkOtherLangs={selectors.getUseBrowserLanguageForPageTemplate(pageTemplate) ? null : currentPageLinkOtherLangs}
                filledWhenScrolledUp={headerMenuFilledWhenScrolledUp}
              />
            )}

            {useStickyContainer && <StickyContainer>{this.renderRouterSwitch()}</StickyContainer>}
            {!useStickyContainer && this.renderRouterSwitch()}

            {(DEBUG || window.debugMode) && (
              <div style={{position: "fixed", fontSize: 12, left: 0, bottom: 0, backgroundColor: "rgba(255,255,0,0.5)", padding: 10}}>
                <div style={{fontWeight: "bold"}}>AppWithState.js</div>
                <Container>
                  <Row>
                    <Col xl={3} lg={4} md={5} sm={6} xs={12} className={"col-debug"}>
                      <div>
                        {JSON.stringify(stageDimensions)}, {`${stageDimensions.height / 2}px`}
                      </div>
                    </Col>
                    <Col xl={3} lg={8} md={7} sm={6} xs={12} className={"col-debug"}>
                      mediaQueryClass: {mediaQueryClass}
                    </Col>
                    <Col xl={3} lg={8} md={7} sm={6} xs={12} className={"col-debug"}>
                      <div>intl test: {intl.formatMessage({id: `LoginButtonFacebookText`})}</div>
                    </Col>
                    <Col xl={3} lg={4} md={5} sm={6} xs={12} className={"col-debug"}>
                      <div className={"xl-only"}>NB-XL (Big Desktop)</div>
                      <div className={"lg-only"}>NB-LG (Desktop)</div>
                      <div className={"md-only"}>NB-MD (Tablet)</div>
                      <div className={"sm-only"}>NB-SM (Tablet / Big Phone)</div>
                      <div className={"xs-only"}>NB-XS (Phone)</div>
                    </Col>
                  </Row>
                </Container>
                <div>location: {JSON.stringify(this.props.location)}</div>
                <div>{intlLocale}</div>
                <Link to={"/"}>home</Link>| <Link to={"/de"}>home (de)</Link>| <Link to={"/yo"}>yo</Link>| <Link to={"/events/20"}>event 20</Link>| <Link to={"/bullshit"}>bullshit 404</Link>
              </div>
            )}

            {false && <CAlert />}
            <CVideoOverlay />
            <CImageOverlay />
            <CLightbox />
            <CReferrerCodePopup />
            <ReactTooltip place={"right"} multiline isCapture effect={"solid"} />

            {!embeddedMode && <CCookieNotice />}

            {!embeddedMode && <CBestOfSwissApps />}

            {drawPlaceholdersBottom && (
              <Fragment>
                <div style={{height: 1000, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "top"}} className={classnames(`appwithstate-dummy-3`, waypointClassName)}></div>
              </Fragment>
            )}

            {DEBUG_MEDIA_QUERY && (
              <div style={{position: "fixed", left: 0, top: 0, backgroundColor: "rgba(255,255,255,0.5)", fontSize: 12, fontWeight: 600, padding: 5, zIndex: 10000}}>
                {mediaQueryClass.toUpperCase()} {stageDimensions.width}px {locationPathname}
              </div>
            )}
          </ThemeProvider>
        </CookiesProvider>
      </PageVisibility>
    )
  }
}

const mapStateToProps = (state, props) => ({
  waypointClassName: state.session.waypointClassName,
  langs: selectors.getLangs(state, props),
  intlLocale: selectors.getIntlLocale(state),
  intlLocaleLocation: selectors.getIntlLocaleLocation(state, props),
  documentTitle: selectors.getDocumentTitle(state, props),
  sitemapData: state.routes.sitemap.data,
  currentPageLinkOtherLangs: selectors.getCurrentPageLinkOtherLangs(state, props),
  locationPathname: selectors.getLocationPathname(state, props),
  pageTemplate: selectors.getPageTemplate(state, props),
  deviceLocaleUsable: selectors.getDeviceLocaleUsable(state, props),
  headerMenuFilledWhenScrolledUp: selectors.getHeaderMenuFilledWhenScrolledUp(state, props),
  embeddedMode: state.session.embeddedMode,
  tabVisible: state.device.tabVisible,
  // DEBUG
  ...(DEBUG || DEBUG_MEDIA_QUERY
    ? {
        stageDimensions: state.device.stageDimensions,
        mediaQueryClass: selectors.getMediaQueryClass(state, props),
      }
    : {
        stageDimensions: {width: -1, height: -1},
        mediaQueryClass: "-",
      }),
})
export default withController(injectIntl(connect(mapStateToProps)(AppWithState)))

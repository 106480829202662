import shortid from "shortid"
import {updateIntl} from "react-intl-redux"
import axios from "axios"

import * as actionsConsts from "../../data/actionsConsts"
import {getObjectDeep as god, getLocaleSafe} from "../selectors/helpers"
import {getLangDynamic} from "../../lang/langsDynamic"

import {sendAnalyticsEvent} from "./AAnalytics"
import {createShareLink} from "./ABranch"
import {requestSitemap, requestGlobals} from "./ARoutes"
import {requestWordpressUser} from "./AUser"
import {clearContactRequests, clearPostData, refreshGeoInfos} from "./AAPI" // eslint-disable-line

export const setWaypointThemeKey = themeKey => dispatch => {
  dispatch({type: actionsConsts.SET_WAYPOINT_THEME_KEY, themeKey})
}

export const requestApiData = () => async dispatch => {
  //  prettier-ignore
  await Promise.all([
    dispatch(clearContactRequests()),
    // dispatch(clearPostData()),
    dispatch(requestSitemap()), 
    dispatch(requestGlobals()),
    dispatch(requestWordpressUser(__DEV__)),
    dispatch(refreshGeoInfos()),
  ])
}

export const setIntlLocale = locale => dispatch => {
  const localeSafe = getLocaleSafe(locale)
  getLangDynamic(localeSafe).then(langData => {
    dispatch(updateIntl({locale: localeSafe, messages: langData.messages}))
  })
}

export const showAlert = data => dispatch => {
  const title = god(data, "title")
  const text = god(data, "text")
  const intlIdDefault = "ButtonOkay"
  const buttonIntlIds = god(data, "buttonIntlIds", [intlIdDefault])
  const onButtonPress = god(data, "onButtonPress", null)
  const id = shortid.generate()
  console.warn("ShowAlert: ", data)
  dispatch({type: actionsConsts.SHOW_ALERT, data: {title, text, buttonIntlIds, onButtonPress, id}})
}

export const showVideoOverlay = (header, youtubeUrl) => dispatch => {
  dispatch({type: actionsConsts.SHOW_VIDEO_OVERLAY, header, youtubeUrl})
}

export const closeVideoOverlay = () => dispatch => {
  dispatch({type: actionsConsts.HIDE_VIDEO_OVERLAY})
}

export const showLightbox = wpImages => dispatch => {
  dispatch({type: actionsConsts.SHOW_LIGHTBOX, wpImages})
}

export const closeLightbox = wpImages => dispatch => {
  dispatch({type: actionsConsts.HIDE_LIGHTBOX, wpImages})
}
export const showImageOverlay = image => dispatch => {
  const type = god(image, "extraData.type")
  if (type == "wall") {
    const id = god(image, "extraData.wallId")
    dispatch(createShareLink("wall", {id}))
  }
  dispatch({type: actionsConsts.SHOW_IMAGE_OVERLAY, image})
}
export const closeImageOverlay = () => dispatch => {
  dispatch({type: actionsConsts.HIDE_IMAGE_OVERLAY})
}

let lastReferrerCodeSet = null
export const setReferrerCode = code => async (dispatch, getState) => {
  const locale = getState().intl.locale
  const newReferrerCode = `${code}_${locale}`
  if (lastReferrerCodeSet == newReferrerCode) {
    return
  }
  lastReferrerCodeSet = newReferrerCode
  console.log("setReferrerCode()", {code, locale})

  dispatch(sendAnalyticsEvent("referrer_link_opened", {Code: code}))

  const response = await axios.get(`https://iazzu.com/api/v3/referrercode`, {params: {code}})
  const codeData = god(response, "data.response.payload.codeData")
  dispatch({type: actionsConsts.SET_REFERRER_CODE, code, codeData})
  dispatch(showReferrerCodePopup())
}

export const showReferrerCodePopup = () => (dispatch, getState) => {
  const referrerCodePopupShownOnce = god(getState(), "session.referrerCodePopupShownOnce", true)
  const referrerCodeData = god(getState(), "session.referrerCodeData", null)
  if (!!referrerCodeData && !referrerCodePopupShownOnce) {
    dispatch({type: actionsConsts.SHOW_REFERRER_CODE_POPUP})
  }
}

export const closeReferrerCodePopup = data => (dispatch, getState) => {
  const clickedButton = god(data, "clickedButton", false)
  const referrerCode = god(getState(), "session.referrerCode", null)
  if (clickedButton && !!referrerCode) {
    dispatch(sendAnalyticsEvent("referrer_popup_button_clicked", {Code: referrerCode}))
  }
  dispatch({type: actionsConsts.CLOSE_REFERRER_CODE_POPUP})
}

export const setEmbeddedModeActive = embeddedMode => dispatch => {
  dispatch({type: actionsConsts.SET_EMBEDDED_MODE_ACTIVE, active: embeddedMode})
}

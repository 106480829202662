import {createSelector} from "reselect"
import {getObjectDeep as god} from "./helpers"
import {getIntlLocale} from "./intl"

const getStateSessionReferrerCodeData = state => god(state, "session.referrerCodeData")
export const getReferrerPopupDataLocalized = createSelector(
  //
  [getStateSessionReferrerCodeData, getIntlLocale],
  (stateSessionReferrerCodeData, intlLocale) => {
    const extractText = key => god(stateSessionReferrerCodeData, `languageVariants.${intlLocale}.${key}`, god(stateSessionReferrerCodeData, `${key}`))
    const data = {
      popupHeader: extractText("popupHeader"),
      popupText: extractText("popupText"),
      popupButtonLabel: extractText("popupButtonLabel"),
      formPrefilledMessage: extractText("formPrefilledMessage"),
    }
    console.log("getReferrerPopupDataLocalized()", {intlLocale, data})
    return data
  },
)

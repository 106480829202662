import React, {Component} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import {injectIntl} from "react-intl"
import {connect} from "react-redux"
import styled, {css} from "styled-components" // eslint-disable-line
import * as selectors from "../../data/selectors"
// const god = selectors.getObjectDeep

const IMAGE_URL = "https://iazzu.com/img/BestOfSwissAppsLogoSmaller.png"
const LINK_URL = "https://iazzu.com/links/best-of-swiss-apps-2024-button"

import {debbify} from "../../data/selectors/helpers"
const debby = (...args) => debbify("CBestOfSwissApps", ...args)

class CBestOfSwissApps extends Component {
  static propTypes = {
    debug: PropTypes.bool,
  }
  static defaultProps = {
    debug: false && __DEV__,
  }

  componentDidMount = () => {}

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  onOkayClick = () => {
    // !!e && e.preventDefault()
    // this.props.cookies.set(COOKIE_ACCEPTED_NAME, JSON.stringify(!this.props.accepted), {path: "/"})
  }

  render = () => {
    const {debug} = this.props
    const d = {debug: debug || CBestOfSwissApps.defaultProps.debug}

    debby("render()")
    return (
      <Container {...d}>
        <Button href={LINK_URL} target="_blank" {...d} onClick={this.onOkayClick}>
          <Image src={IMAGE_URL} />
        </Button>
      </Container>
    )
  }
}

const Container = styled.div`
  z-index: 1000;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex: 1;
  pointer-events: none;
  background-color: ${props => (props.debug ? "red" : "none")};

  padding: 15px;

  justify-content: center;
  align-items: center;

  ${props =>
    css`
      ${props.theme.media.lg} {
        // width: 100px;
        // height: 100px;
      }
      ${props.theme.media.sm} {
        justify-content: center;
      }
      ${props.theme.media.xs} {
        justify-content: flex-end;
        align-items: flex-end;
        bottom: 50px;
      }
    `}

},
`

const Button = styled.a`
  align-items: center;
  justify-content: center;
  cursor: pointer;
  pointer-events: auto;
  background-color: ${props => (props.debug ? "blue" : "none")};
`

const Image = styled.img`
  filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.5));

  object-fit: contain;

  @keyframes endless-in {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(0.95);
    }
    100% {
      transform: scale(1);
    }
  }
  animation: endless-in infinite 4s ease;

  width: 130px;
  height: 130px;

  ${props =>
    css`
      ${props.theme.media.xl} {
        width: 150px;
        height: 150px;
      }
      ${props.theme.media.lg} {
        width: 130px;
        height: 130px;
      }
      ${props.theme.media.sm} {
      }
      ${props.theme.media.xs} {
      }
    `}
`

const mapStateToProps = state => ({
  intlLocale: selectors.getIntlLocale(state),
  globalsLocalized: selectors.getGlobalsLocalized(state),
})
export default injectIntl(connect(mapStateToProps)(CBestOfSwissApps))
